import { COPYRIGHT } from "@Platon/const"

export default {
	computed: {
		plotOptions() {
			return {
				plotOptions: {
					map: {
						allAreas: !this.isMultiSeries,
						joinBy: ["id", "id"],
						dataLabels: {
							enabled: true
						}
					}
				}
			}
		},

		isMultiSeries() {
			return this.chartData.length > 1
		},

		colors() {
			return this.options.colors || ["#fff", "#335d99"]
		},

		geoPolygons() {
			return {
				title: "Uzbekistan",
				version: "1.1.2",
				type: "FeatureCollection",
				copyright: COPYRIGHT,
				crs: { type: "name", properties: { name: "urn:ogc:def:crs:EPSG:32641" } },
				"hc-transform": {
					default: {
						crs: "+proj=utm +zone=41 +datum=WGS84 +units=m +no_defs",
						scale: 0.000484870791052,
						jsonres: 15.5,
						jsonmarginX: -999,
						jsonmarginY: 9851.0,
						xoffset: -87833.2315425,
						yoffset: 5054739.43841
					}
				},
				features: (this.options.polygons || []).map((item) => {
					return {
						type: "Feature",
						id: item.id,
						properties: {
							...item,
							id: item.id,
							name: item.name
						},
						geometry: typeof item.polygon === "object" ? item.polygon : JSON.parse(item.polygon)
					}
				})
			}
		},

		seriesData() {
			return this.chartData.map((serie, index) => {
				return {
					name: serie.name || "",
					type: "map",
					data: serie.data,
					joinBy: ["id", "id"],
					dataLabels: {
						enabled: this.options.showDataLabels,
						format: "{point." + (this.options.label || "name") + "}",
						...(serie.dataLabels || {})
					},
					tooltip: {
						pointFormat: this.options.tooltipFormat || "{point.name}: {point.value}"
					},
					events: {
						click: this.options.onclick || (() => {}),
						dblclick: this.options.dblclick || (() => {})
					},
					...(this.isMultiSeries
						? {
								color: this.colors[index % this.colors.length]
								//borderColor: this.colors[index % this.colors.length]
						  }
						: {}),
					...(this.options.showTooltipOnClick
						? {
								point: {
									events: {
										click: function () {
											this.series.chart.update({
												tooltip: { enabled: true }
											})
										},
										mouseOut: function () {
											this.series.chart.update({
												tooltip: { enabled: false }
											})
										}
									}
								}
						  }
						: {})
				}
			})
		}
	},

	methods: {
		formatOptions(Chart) {
			let options = {
				chart: {
					map: Chart.geojson(this.geoPolygons, "map"),
					type: "map"
				},

				dataLabels: {
					enabled: true,
					format: "{point.name}"
				},

				tooltip: {
					enabled: !this.options.showTooltipOnClick,
					positioner: function (lw) {
						return { x: this.chart.chartWidth - lw - 10, y: 30 }
					},
					borderWidth: 0,
					shadow: false,
					style: { fontSize: "10pt" }
				},

				...(this.isMultiSeries
					? {}
					: {
							colorAxis: {
								minColor: this.colors.concat().shift(),
								maxColor: this.colors.concat().pop(),
								min: this.options.minVal,
								max: this.options.maxVal
							}
					  }),

				legend: {
					title: {
						text: this.options.legendTitle || "Кўрсаткичлар"
					},

					align: "left",
					floating: true,
					layout: this.isMultiSeries ? "vertical" : "",
					verticalAlign: "bottom"
				},

				series: this.seriesData
			}

			return Chart.merge(true, this.defaultOptions, this.plotOptions, options, this.options.chartConfig || {})
		}
	}
}
